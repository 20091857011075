<template>
  <img v-if="fileUrl" :src="fileUrl" />
</template>

<script>
import Api2 from "@/services/Api2";
import AWSUrlApi from "@/services/AWSUrlApi";
export default {
  props: ["src"],
  data() {
    return {
      fileUrl: null,
    };
  },
  methods: {
    getFileUrl() {
      if (this.src && this.src != "") {
        if (this.src.indexOf('https:') == 0 && this.src.indexOf('bjjlink') > 0) {
          //This is the case like above when I am trying to get a signed url 
          const encodedUrl = encodeURIComponent(this.src);
          Api2()
            .getWithCache("content/url/signed/" + encodedUrl, {}, 3000)//3600 is the time we get the image signed for
            .then(res => {
              this.fileUrl = res;
            });
        } else {
          const path = this.src;
          if (path && path != "" && path.indexOf("https:") == 0) this.fileUrl = path;//regular image
          else if (path.indexOf("userfiles") >= 0)
            this.fileUrl = process.env.VUE_APP_API_HOST + "/api/" + path;
          else if (path.indexOf("media") >= 0) this.fileUrl = process.env.VUE_APP_API_HOST + "/api/" + path;
          else
            AWSUrlApi()
              .get(this.src)
              .then((res) => {
                this.fileUrl = res.data;
              });
        }
      }
    },
  },
  created() {
    this.getFileUrl();
    //Viewed?
  },
};
</script>

<style scoped></style>
