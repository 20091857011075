import Vue from "vue";
import Vuetify from "vuetify/lib";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify, {});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //New Colors
        primary: "#000000",
        opposite: "#FFFFFF",
        secondary: "#6969FF",
        neutral: "#EFECEC",
        neutralVarient: "CCCCCC",
        success: "#6DF481",
        error: "#FF5F5F",
        accent: "#B4B4FF",
        gray: "#000000",
        graph1: "#6FB6FF",
        graph2: "#A8F8B3",
        graph3: "#FFAFAF",

        //old Stuff - avoid using
        terciary: "#C8C2FC",
        tertiary: "#C8C2FC",
        background: colors.grey.lighten4,
        title: "#1E1E1E",
        alt1: "#f39b36",
        icon: colors.white,
        green: "#ADF792",
        yellow: "#FFED1A",
        white: "#FFFFFF",
        blue: "#2986FF",
        purple: "#8E54CC",
        brown: "#9C6636",
        black: "#000000",
        gold: "#fbc42e",
        silver: "#B8B8B8",
        bronze: "#ce8740",
        orange: "#fd9927",
      },
      dark: {
        //New Colors
        primary: "#FFFFFF",
        opposite: "#000000",
        secondary: "#4444FF",
        neutral: "#808080",
        neutralVarient: "E60000",
        success: "#12E02F",
        error: "#FF5F5F",
        accent: "#f36284",
        gray: "#EFECEC",
        graph1: "#6FB6FF",
        graph2: "#6DF481",
        graph3: "#fd9927",

        //old Stuff - avoid using
        terciary: "#C8C2FC",
        tertiary: "#C8C2FC",
        background: colors.grey.darken3,
        title: "#FFFFFF",
        alt1: "#f39b36",
        green: "#ADF792",
        yellow: "#FFED1A",
        white: "#FFFFFF",
        blue: "#2986FF",
        purple: "#8E54CC",
        brown: "#9C6636",
        black: "#000000",
        gold: "#fbc42e",
        silver: "#B8B8B8",
        bronze: "#ce8740",
        orange: "#fd9927",
      },
    },
  },
});
