<template>
  <div class="fill-height">
    <v-card-actions class="pa-0">
      <BackButton></BackButton>
      <v-spacer></v-spacer>
      <v-btn class="mr-2" icon right small @click="sendInfoDialog = true">
        <v-icon>mdi-help-rhombus-outline</v-icon>
      </v-btn>
    </v-card-actions>
    <v-sheet class="fill-height" :style="treeHeight" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-actions class="pb-0 mb-0 pl-1">
        <v-autocomplete :return-object="true" :disabled="traversingTree || loading" :items="treeNodes"
          :label="$t('message.search_tree')" v-model="selectedTreeName" item-text="name" item-value="_id"
          :clearable="true" :loading="loadingTreeNodes" outlined dense hide-no-data :search-input.sync="searchTree"
          class="pa-0 autocomplete" :disable-lookup="true" style="margin-bottom:-14px">
          <template slot="no-data">
            <v-list-item>
              <span class="subheading">{{
                $t("message.no_data_matching")
                }}</span>
            </v-list-item>
          </template>
          <template slot="item" slot-scope="data">
            <v-list-item-avatar v-if="data.item && data.item.user">
              <img :src="userImg(data.item.user.userImageSmall)" />
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item>
              <v-list-item-content v-if="data.item">{{
                data.item.name
                }}</v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-card-actions>
      <v-card-text class="text-center" v-if="findingNode">
        <v-progress-circular v-if="traversingTree" :width="6" :size="70" color="green"
          indeterminate></v-progress-circular>
        <div class="mr-1">{{ $t("message.finding") }}</div>
        <b>{{ findingNode }}</b>
      </v-card-text>
      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate size="100" color="success">
          <IconLineage size="30" :color="themeColor('success')"></IconLineage>
        </v-progress-circular>
        <div class="text-body-2">{{ $t("message.loading_lineage_tree") }}</div>
      </div>
      <div v-if="tree">
        <v-sheet :style="treeWidth">
          <ul style="padding-left:2px;">
            <TreeItem @completed-autoload="completedAutoload" :autoload="autoload" style="list-style: none;"
              :edit-children="true" :item="tree" ref="treeRoot">
            </TreeItem>
          </ul>
        </v-sheet>
      </div>
    </v-sheet>
    <v-dialog v-model="sendInfoDialog" max-width="90%">
      <v-card outlined>
        <v-card-title class="text-subtitle-1">
          <v-icon>mdi-help-rhombus-outline</v-icon>
          {{ $t("message.questions") }}?
        </v-card-title>
        <v-card-text class="text-left">
          <div>{{ $t("message.lineage_questions") }}</div>
          <div>{{ $t("message.lineage_questions2") }}</div>
        </v-card-text>
        <v-card-text>
          <v-textarea outlined dense v-model="lineageQuestions" rows="2"
            style="margin-left: 5px; margin-right: 5px; padding-top: 5px"
            :label="$t('message.enter_text_here')"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn elevation="0" color="primary" @click="sendLineageInfo()">{{
            $t("message.send_info")
            }}</v-btn>
          <v-btn elevation="0" @click="sendInfoDialog = false">{{
            $t("message.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LineageService from "@/services/LineageService";
import TreeItem from "@/components/displays/TreeItem.vue";
import IconLineage from "@/components/displays/icons/IconLineage";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

export default {
  data() {
    return {
      nodeId: this.$route.query.n,
      findingNode: null,
      tree: null,
      loading: true,
      treeNodes: [],
      loadingTreeNodes: false,
      selectedTreeName: null,
      sendInfoDialog: false,
      searchTree: null,
      lineageQuestions: null,
      traversingTree: false,
      treeHeight: 'height:100%',
      treeWidth: 'width:100%',
      autoload: null,
    };
  },
  components: {
    TreeItem,
    IconLineage,
    BackButton
  },
  watch: {
    searchTree(val) {
      val && val.length > 2 && this.querySelections(val)
    },
    selectedTreeName(val) {
      if (val) {
        this.nodeId = val._id;
        this.getTree("root", true);
      }
    }
  },
  created() {
    this.getTree("root", true);
    const height = window.innerHeight - 114;
    //const width = window.innerWidth;
    this.treeHeight = 'height:' + height + 'px';
    //this.treeWidth = 'width:' + width + 'px;overflow-x:auto';
  },
  methods: {
    querySelections(v) {
      if (!this.loadingTreeNodes) {
        this.loadingTreeNodes = true
        LineageService.treeNodes(v)
          .then((data) => {
            if (data) {
              this.treeNodes = data.nodes;
              this.loadingTreeNodes = false;
            }
          }).catch(() => {
            this.loadingTreeNodes = false;
          });
      }
    },
    searchTreeOLD(val) {
      val && val !== this.select && this.querySelections(val)
    },
    completedAutoload() {
      this.traversingTree = false;
      this.findingNode = null;
    },
    searchNode(val) {
      LineageService.treeNodes(val)
        .then((data) => {
          if (data) {
            this.treeNodes = data.nodes;
          }
        });
    },
    sendLineageInfo() {
      if (this.lineageQuestions && this.lineageQuestions != "") {
        LineageService.sendQuestions({ contents: this.lineageQuestions }).then(
          (data) => {
            if (data) {
              this.sendInfoDialog = false;
              this.showAlert("success", this.$t("message.success"));
            }
          }
        );
      }
    },
    getTree(nodeId, root) {
      this.loading = true;
      this.tree = null;
      LineageService.children(nodeId, root).then((data) => {
        if (data) {
          if (root) this.tree = data.root;
          this.loading = false;
          if (this.nodeId) {
            this.traverseTree(this.nodeId);
          } else if (this.tree && this.tree.children && this.tree.children[0]) {
            this.nodeId = this.tree.children[0]._id;
            this.traverseTree(this.nodeId);
          }
        }
      });
    },
    traverseTree(nodeId) {
      this.traversingTree = true;
      LineageService.getLineageById(nodeId).then((data) => {
        if (data && data[0]) {
          this.findingNode = data[0].name;
          let completeLineage = [];
          data.reverse();
          for (const d of data) {
            completeLineage.push(d._id)
          }
          this.autoload = completeLineage;
        } else {
          this.traversingTree = false;
        }
      });
    },
  },
};
</script>

<style scoped></style>