<template>
  <v-container v-if="$store.state.isUserLoggedIn" fill-height class="pa-0" style="overflow: hidden;">
    <v-row no-gutters>
      <v-col>
        <v-card tile v-if="myAcademyData" :height="mainPanelHeight">
          <v-toolbar height="30" class="pa-0 opposite--text" dense flat color="primary">
            <span class="mr-2 font-weight-bold text-h6">/</span>
            {{ myAcademyData.academyName }}
            <v-spacer></v-spacer>
            <v-btn @click="navigateTo({ name: 'myacademytab', params: { tabId: 0 }, query: { alerts: 'yes' } })"
              v-if="myAcademyData.alertsCount" x-small elevation="0" text color="error">
              <v-icon size="25">mdi-chat-alert-outline</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-1">
            <v-row dense>
              <v-col cols="8">
                <v-card flat>
                  <v-tabs v-model="scheduleTab" show-arrows>
                    <v-tab :key="'classes_today'">
                      {{ $t("message.todays_schedule") }}
                    </v-tab>
                    <v-tab v-if="hasAvailableClasses" :key="'classes_available'">
                      {{ $t("message.available_classes") }}
                    </v-tab>
                    <v-tab :key="'classes_akk'">{{
                      $t("message.full_schedule") }}
                    </v-tab>
                    <v-tab-item :key="'classes_today'">
                      <v-skeleton-loader v-if="loadingClasses" type="list-item-two-line"></v-skeleton-loader>
                      <div v-else style="height: 160px;overflow-y: auto;">
                        <div v-if="!upcomingEvents || !upcomingEvents.length">
                          No Classes to Show
                        </div>
                        <v-card class="mt-1" tile outlined v-for="(e, index) in upcomingEvents"
                          :key="'upcoming_event_' + index">
                          <div class="pl-2">
                            <v-list-item outlined tile @click="goToAcademyClass(e)" class="pr-0 pl-0">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ e.title }}
                                  {{ e.professorUser ? "(" + $t("message.professor") + ": " +
                                    e.professorUser.displayName + ")" : "" }}
                                </v-list-item-title>
                                <v-list-item-title>
                                  <span style="color: gray;">
                                    {{ $d(new Date(e.date), "long", $i18n.locale) }}
                                  </span>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </div>
                        </v-card>
                      </div>
                    </v-tab-item>
                    <v-tab-item v-if="hasAvailableClasses" :key="'classes_available'">
                      <v-skeleton-loader v-if="loadingClasses" type="list-item-two-line"></v-skeleton-loader>
                      <div v-else style="height: 160px;overflow-y: auto;">
                        <v-card elevation="0">
                          <v-list two-line>
                            <template v-for="(e, index2) in availableClasses">
                              <v-list-item :key="'academy_class_' + '_' + index2" @click="goToAcademyClass(e)">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ e.title }}
                                  </v-list-item-title>
                                  <v-list-item-title v-if="e.disabled" class="error--text">
                                    {{ $t("message.cancelled") }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    <span v-if="e.maxCapacity > 0"
                                      :class="'font-weight-bold mr-1 ' + (e.hasCapacity ? 'success--text' : 'error--text')">
                                      {{ e.approved ? e.approved.length : 0 }}\{{ e.maxCapacity }}
                                    </span>
                                    <span v-if="e.professorUser">{{ e.professorUser.displayName }}</span>
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    {{ $d(e.date, "long", $i18n.locale) }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action v-if="e.status || e.canJoin || e.canWaitlist">
                                  <v-btn @click.stop="joinAcademyClass(e)" color="primary" elevation="0" small icon
                                    v-if="!e.status && e.canJoin">
                                    <v-icon>mdi-login</v-icon>
                                  </v-btn>
                                  <v-btn @click.stop="joinAcademyClass(e)" color="secondary" elevation="0" small icon
                                    v-else-if="!e.status && e.canWaitlist">
                                    <v-icon>mdi-login</v-icon>
                                  </v-btn>
                                  <v-btn icon elevation="0" :color="e.canWaitlist ? 'error' : ''" small
                                    v-else-if="e.status == 'pending'">
                                    <v-icon>mdi-clock-time-five-outline</v-icon>
                                  </v-btn>
                                  <v-btn color="success" elevation="0" icon small medium
                                    v-else-if="e.status == 'approved'">
                                    <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-card>
                      </div>
                    </v-tab-item>
                    <v-tab-item :key="'full_schedule'">
                      <v-skeleton-loader v-if="loadingClasses" type="list-item-two-line"></v-skeleton-loader>
                      <div v-else style="height: 160px;overflow-y: auto;">
                        <div v-if="scheduleNotes" class="text-center pa-1">{{ scheduleNotes.notes }}</div>
                        <div class="pa-1">
                          <ScheduleDisplay :professors="professors" :schedule="schedule" />
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card flat>
                  <v-card-actions class="text-caption">
                    <span></span>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                  <v-btn @click="navigateTo({ name: 'viewposts', params: { feed: 'academy' } })" outlined small block
                    color="primary" elevation="0">
                    {{ $t("message.bulletin") }}
                    <v-spacer></v-spacer>
                    <span class="text--secondary" text v-if="bulletinData.last24">
                      +{{ bulletinData.last24 }}
                    </span>
                  </v-btn>
                  <v-btn @click="navigateTo({ name: 'videos' })" outlined small block color="primary" class="mt-1">
                    {{ $t("message.videos") }}
                    <v-spacer></v-spacer>
                  </v-btn>
                  <v-btn @click="navigateTo({ name: 'lineagetree', query: { n: myAcademyData.academyLineageNode } })"
                    outlined small block color="primary" class="mt-1">
                    {{ $t("message.lineage") }}
                    <v-spacer></v-spacer>
                  </v-btn>
                  <v-btn @click="this.navigateTo({ name: 'myacademytab', params: { tabId: 0 } })" outlined small block
                    color="primary" class="mt-1">
                    {{ $t("message.notes") }}
                    <v-spacer></v-spacer>
                  </v-btn>
                  <v-btn outlined small block color="primary" class="mt-1"
                    @click="navigateTo({ name: 'myacademytab', params: { tabId: 0 } })">
                    {{ $t("message.view_more") }}
                    <v-spacer></v-spacer>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
            <v-card-actions light>
              <template v-if="myAcademyData.progress && myAcademyData.progress.value">
                <v-progress-circular :width="5" :size="25" @click="goToInstance()" outlined
                  v-if="myAcademyData.progress.jjtemplate" :color="myAcademyData.progress.color"
                  :value="myAcademyData.progress.value">
                </v-progress-circular>
                <v-progress-circular :width="5" :size="25" v-else @click="goToInstance()"
                  :color="myAcademyData.progress.level" :value="myAcademyData.progress.value">
                </v-progress-circular><span class="ml-1">{{ myAcademyData.progress.value }}%</span>
                <v-spacer></v-spacer>
              </template>
              <template v-if="points && points.positions && points.positions.academyPosition">
                <span class="text-caption ">
                  <b class="mr-1 text-subtitle-1">{{ points.positions.academyPosition }}</b>
                  {{ $t("message.in_your_academy").toLowerCase() }}
                </span>
                <v-spacer></v-spacer>
              </template>
              <v-avatar size="25" @click="this.navigateTo({ name: 'myacademytab', params: { tabId: 0 } })">
                <ImageDisplay :src="myAcademyData.academyImage" />
              </v-avatar>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col>
        <v-card tile :height="mainPanelHeight + 10">
          <v-toolbar height="30" class="pa-0 opposite--text" dense flat color="primary">
            <span class="mr-2 font-weight-bold text-h6">/</span>
            {{ $t("message.my_training") }}
          </v-toolbar>
          <div v-if="myTrainingData.loading">
            <v-skeleton-loader tile :height="mainPanelHeight - 30" type="image"></v-skeleton-loader>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="4" class="pr-0 pb-0">
                <v-card flat class="mt-3" @click="navigateTo({ name: 'viewmytrainingtab', params: { tabId: 100 } })">
                  <div class="font-weight-bold pl-2">
                    <div>{{ $t("message.this_week").toUpperCase() }}</div>
                    <div>
                      <div class="text-h4">
                        {{ myTrainingData.currentWeekData.thisWeekMinutes }}
                        <span class="text-caption"
                          v-if="!isNaN(myTrainingData.currentWeekData.deltaPercent) && myTrainingData.currentWeekData.deltaPercent != 0">
                          <v-icon color="error"
                            v-if="myTrainingData.currentWeekData.deltaPercent < 0">mdi-chevron-double-down</v-icon>
                          <v-icon v-else color="success">mdi-chevron-double-up</v-icon>{{
                            Math.abs(myTrainingData.currentWeekData.deltaPercent) }}%
                        </span>
                      </div>
                      <div class="text-caption text-truncate">{{ $t("message.minutes_trained").toUpperCase() }}</div>
                    </div>
                  </div>
                </v-card>
                <v-card flat class="mt-3 gray--text">
                  <div class="font-weight-bold pl-2">
                    <div>{{ $t("message.last_week").toUpperCase() }}</div>
                    <div>
                      <div class="text-h4">{{ myTrainingData.currentWeekData.lastWeekMinutes }}</div>
                      <div class="text-caption text-truncate">{{ $t("message.minutes_trained").toUpperCase() }}</div>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8" class="pb-0">
                <ApexChart v-if="myTrainingData.trendline" type="line" height="200"
                  :options="myTrainingData.trendline.options" :series="myTrainingData.trendline.series">
                </ApexChart>
                <v-card-text v-else class="text-center">
                  <p>{{ $t("message.no_minutes_recorded") }}</p>
                  <p>{{ $t("message.no_minutes_recorded2") }}</p>
                </v-card-text>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn @click="navigateTo({ name: 'viewmytrainingtab', params: { tabId: 1 } })" small text><v-icon
                  class="mr-1">mdi-book-open-variant-outline</v-icon>{{ $t("message.journal")
                  }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="pointsDialog = true;" small text><span class="mr-1 font-weight-bold">{{ points.total
              }}</span>{{
                    $t("message.points")
                  }}</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col cols="6">
        <v-card tile flat>
          <v-toolbar height="30" class="pa-0 opposite--text" dense flat color="primary">
            <span class="mr-2 font-weight-bold text-h6">/</span>
            {{ $t("message.match_tracker") }}
          </v-toolbar>
          <div v-if="matchTrackerData.loading">
            <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
          </div>
          <div v-else>
            <v-list dense>
              <v-list-item dense>
                <v-list-item-content class="pt-0 pb-0 mb-0 mt-0">
                  <v-list-item-title>{{ $t("message.saved") }}</v-list-item-title>
                  <v-list-item-title>{{ $t("message.competitors") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="pl-1 ml-1 pa-0">
                  <v-btn x-small elevation="0" fab>
                    {{ matchTrackerData.savedCompetitorsCount }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content class="pt-0 pb-0 mb-0 mt-0">
                  <v-list-item-title>{{ $t("message.active") }}</v-list-item-title>
                  <v-list-item-title>{{ $t("message.competing") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="pl-1 ml-1 pa-0 mb-0 mt-0">
                  <v-btn x-small elevation="0" fab>
                    {{ matchTrackerData.savedCompetitorsCompetingCount }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content class="pt-0 pb-0 mb-0 mt-0">
                  <v-list-item-title>{{ $t("message.active") }}</v-list-item-title>
                  <v-list-item-title>{{ $t("message.events") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn x-small elevation="0" fab>
                    {{ matchTrackerData.activeEventsCount }}
                  </v-btn>
                </v-list-item-action>
                <v-list-item-avatar size="30" class="pl-1 ml-1 pa-0" outlined>
                  <img :src="assets() + '/external-logos/ibjjf-logo-blue.png'" />
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
            <v-card-actions class="pb-0 pt-0" style="margin-top:-2px">
              <v-btn elevation="0" @click="go2MatchTracker" block text small color="primary">
                <span class="mr-1">{{ $t("message.view_more") }}</span>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card tile flat>
          <v-toolbar height="30" class="pa-0 opposite--text" dense flat color="primary">
            <span class="mr-2 font-weight-bold text-h6">/</span>
            {{ $t("message.news") }}
          </v-toolbar>
          <div v-if="newsData.loading">
            <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
          </div>
          <div v-else>
            <v-list dense>
              <template v-for="(i, index) in newsData.last3">
                <v-list-item :key="'home_news_' + index" dense class="pt-0 pb-0 mb-0 mt-0">
                  <v-list-item-avatar tile class="pa-0" size="30">
                    <v-img :src="userFile(i.image)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-truncate" style="overflow: hidden;">
                      {{ truncate(i.title, 20) }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-truncate" style="overflow: hidden;">
                      {{ truncate(i.description, 20) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-card-actions class="pb-0 pt-0">
              <v-btn elevation="0" @click="go2News" block text small color="primary">
                <span class="mr-1">{{ $t("message.new_today") }}</span>
                <span class="text-h6">{{ newsData.newToday }}</span>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="pointsDialog">
      <v-card outlined v-if="points.positions">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon small @click="pointsDialog = false"><v-icon>mdi-close-circle</v-icon></v-btn>
        </v-card-actions>
        <v-card-title>{{ $t("message.points") }}</v-card-title>
        <v-list dense>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.total_points") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.total }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.last_30_days") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.last30Days }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.this_month") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.thisMonth }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.academy_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.positions.academyPosition }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.city_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.positions.cityPosition }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.country_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.positions.countryPosition }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.world_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.positions.worldPosition }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn elevation="0" @click="navigateTo({ name: 'points' })" block>{{ $t("message.view_more") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import NotificationService from "@/services/NotificationService";
import ConnectService from "@/services/ConnectService";
import AcademyService from "@/services/AcademyService";
import AcademyCalendarService from "@/services/AcademyCalendarService";
import ScheduleDisplay from "@/components/displays/ScheduleDisplay.vue";
import ApexChart from 'vue-apexcharts';

export default {
  name: 'HomeMember',
  data() {
    return {
      myAcademyData: null,
      mainPanelHeight: 280,
      schedule: null,
      scheduleNotes: null,
      professors: [],
      availableClasses: null,
      isMember: false,
      hasAvailableClasses: false,
      loadingClasses: false,
      classesPanel: null,
      upcomingEvents: [],
      checkinCode: null,
      pointsDialog: false,
      scheduleTab: 0,
      matchTrackerData: {
        loading: true,
        savedCompetitorsCount: 0,
        activeCompetingCount: 0,
        activeEventsCount: 0,
      },
      myTrainingData: {
        loading: true,
        last30Percent: 0,
        minutes: 0,
      },
      newsData: {
        loading: true,
      },
      bulletinData: {
        loading: true,
      },
      points: {
        loading: true,
      }
    };
  },
  components: {
    ScheduleDisplay,
    ApexChart
  },
  mounted() {
    const vm = this;
    vm.getMessagesCount(function (countM) {
      vm.getNotificationsCount(function (countS) {
        vm.$store.dispatch("setMessagesCount", countM);
        vm.$store.dispatch("setNotifications", countS);
        if (countM + countS == 0) {
          NotificationService.clearMobile();
        }
      });
    });
  },
  created() {
    this.initMe();
  },
  methods: {
    async initMe() {
      const me = await ConnectService.getMe();
      if (me) {
        const darkTheme = me.data.darkMode;
        this.$vuetify.theme.dark = darkTheme;
        this.hiddenUser = me.data.hidden;
        if (me.data.isManagedAcademyAdmin && !this.$store.state.managedAcademyAdmin) {
          this.$store.dispatch("setManagedAcademyAdmin", true);
        } else if (!me.data.isManagedAcademyAdmin) {
          this.$store.dispatch("setManagedAcademyAdmin", false);
        }
        if (me.data.isManagedAcademyMember && (!this.$store.state.userManagedAcademy || this.$store.state.userManagedAcademy != me.data.mainManagedAcademy)) {
          this.$store.dispatch("setUserManagedAcademy", me.data.mainManagedAcademy);
        } else if (!me.data.isManagedAcademyMember) {
          this.$store.dispatch("setUserManagedAcademy", null);
        }
        if (!me.data.mainManagedAcademy) {
          if (this.$store.state.version == 4) {
            this.navigateTo({ name: 'home-regular' })
          }
        }
        this.loadData();
      }
    },
    go2MatchTracker() {
      if (this.hiddenUser)
        this.showAlert(
          "info",
          this.$t("message.social_disabled")
        );
      else
        this.navigateTo({ name: 'ibjjftournaments' })
    },
    go2News() {
      if (this.hiddenUser)
        this.showAlert(
          "info",
          this.$t("message.social_disabled")
        );
      else
        this.navigateTo({ name: 'news' })
    },
    goToInstance() {
      if (this.myAcademyData && this.myAcademyData.progress && this.myAcademyData.progress.showDetails)
        this.navigateTo({ name: "viewprogressdetails", params: { instanceId: this.myAcademyData.progress.instanceId } });
    },
    goToAcademyClass(e) {
      //Are you a member?
      if (!e._id)
        return;
      if (e.social) {
        this.navigateTo({
          name: "viewevent",
          params: { eventId: e._id },
        });
      } else if (this.isMember || e.allowNonMembers) {
        this.navigateTo({
          name: "viewacademyclass",
          params: { academyClassId: e._id },
        });
      }
    },
    loadSchedule() {
      this.loadingClasses = true;
      this.getAcademyClasses();
      this.getUpcomingEvents();
    },
    getAcademyClasses() {
      this.loadingClasses = true;
      AcademyService.academyClasses(this.$store.state.userManagedAcademy)
        .then((data) => {
          try {
            if (data.schedule)
              this.schedule = data.schedule;
            if (data.checkinCode)
              this.checkinCode = data.checkinCode;
            if (data.scheduleNotes)
              this.scheduleNotes = data.scheduleNotes;
            if (data.professors)
              this.professors = data.professors;
            this.availableClasses = [];
            if (data.events && data.events.length > 0) {
              this.hasAvailableClasses = true;
              this.isMember = data.isMember;
              this.loadingClasses = false;
              for (let c of data.events) {
                const userId = this.$store.state.user._id;
                const maxCapacity = c.maxCapacity;
                const totalApproved = c.approved ? c.approved.length : 0;
                if (totalApproved < maxCapacity)
                  c.hasCapacity = true;
                if (c.approved && c.approved.indexOf(userId) >= 0) {
                  c["status"] = "approved";
                } else if (c.pending && c.pending.indexOf(userId) >= 0) {
                  c["status"] = "pending";
                  //Check max capacity to see if this is a waiting list person
                  if (c.maxCapacity) {
                    if (totalApproved >= maxCapacity) c["canWaitlist"] = true;
                  }
                } else {
                  if (c.maxCapacity) {
                    if (totalApproved < maxCapacity) {
                      //We have not reached max capacity
                      if (this.isMember || c.allowNonMembers)
                        c["canJoin"] = true;
                    } else if (c.allowWaitlist) {
                      if (this.isMember || c.allowNonMembers)
                        c["canWaitlist"] = true;
                    }
                  }
                }

                const classTime = new Date(c.date);
                c.date = classTime;
                if (!isNaN(c.startTimeHours)) {
                  classTime.setHours(c.startTimeHours);
                  classTime.setMinutes(c.startTimeMinutes);
                  c.date = classTime;
                }
                if (c.classDay)
                  c.date.setDate(c.classDay);
                this.availableClasses.push(c);
              }
            }
          } catch (err) {
            console.log(err);
          }
          this.loadingClasses = false;
        });
    },
    getUpcomingEvents() {
      this.loadingClasses = true;
      AcademyCalendarService.upcomingEvents()
        .then((data) => {
          if (data.events && data.events.length > 0) {
            const tmp = data.events;
            if (tmp && tmp.length > 0) {
              let upcomingEvents = [];
              this.classesPanel = 0;
              const date = new Date();
              for (const i of tmp) {
                if (i.date) {
                  upcomingEvents.push(i);
                } else {
                  const d = this.hourInNumberToDate(i.start, date);
                  upcomingEvents.push({
                    date: d,
                    title: i.title
                  })
                }
              }
              //Sort
              upcomingEvents.sort((e1, e2) => e1.date - e2.date)

              this.upcomingEvents = upcomingEvents;
              this.loadingClasses = false;
            }
          }
        });
    },
    loadData() {
      this.loadSchedule();
      ConnectService.getHomePostsAcademy()
        .then((data) => {
          if (data.success) {
            this.bulletinData = data.data;
          }
        });
      ConnectService.getHomeMatchTracker()
        .then((data) => {
          if (data.success) {
            this.matchTrackerData = data.data;
          }
        });
      ConnectService.getHomePoints()
        .then((data) => {
          this.points = data.points;
          this.points.positions = data.positions;
        });
      ConnectService.getHomeMyTraining()
        .then((data) => {
          if (data.success) {
            this.myTrainingData = data.data;
            if (data.data.currentMonthTrendline && data.data.currentMonthTrendline.length) {
              this.myTrainingData.currentWeekData = data.data.currentWeekData;
              const series = [
                {
                  name: this.$t("message.this_month"),
                  data: data.data.currentMonthTrendline.map((doc) => {
                    return doc.minutes;
                  }),
                },
                {
                  name: this.$t("message.last_month"),
                  data: data.data.previousMonthTrendline.map((doc) => {
                    return doc.minutes;
                  })
                },
              ];
              this.myTrainingData.trendline = {
                options: {
                  colors: [this.themeColor('error'), this.themeColor('gray')],
                  labels: [this.$t("message.this_month"), this.$t("message.last_month")],
                  xaxis: {
                    categories: [1, 2, 3, 4],
                    labels: {
                      show: true,
                    }
                  },
                  markers: {
                    size: 4
                  },
                  yaxis: {
                    title: {
                      show: false
                    },
                    labels: {
                      show: false,
                    }
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: 'smooth',
                    width: [3, 2],
                    dashArray: [0, 6]
                  },
                  theme: {
                    mode: this.isDarkTheme() ? 'dark' : 'light',
                  },
                  chart: {
                    toolbar: {
                      show: false
                    },
                    background: 'none'
                  },
                  legend: {
                    position: 'bottom'
                  },
                  grid: {
                    borderColor: this.themeColor('neutral'),
                    strokeDashArray: 3
                  },
                  tooltip: {
                    enabled: true,
                  }
                },
                series: series
              };
            }
          }
        });
      ConnectService.getHomeNews()
        .then((data) => {
          if (data.success) {
            if (data.data.news) {
              this.newsData = data.data;
              this.newsData.last3 = [data.data.news[0], data.data.news[1], data.data.news[2]];
            }
          }
        });
      ConnectService.getHomeMyAcademy()
        .then((data) => {
          if (data.success && data.data.academy) {
            let myAcademyData = { loading: false };
            myAcademyData.academyName = data.data.academy.name;
            myAcademyData.academyImage = data.data.academy.image;
            myAcademyData.alertsCount = data.data.alerts;
            myAcademyData.topUsers = data.data.topUsers;
            myAcademyData.academyLineageNode = data.data.academyLineageNode;
            if (data.data.progress) {
              myAcademyData.progressPercent = data.data.progress.value;
              myAcademyData.progress = data.data.progress;
            }
            this.myAcademyData = myAcademyData;
          }
        });
    }
  }
}
</script>
<style scoped></style>
